.navigation {
    background-color: #77b300;
    padding: 0 9%; /* Padding from the edges of the screen */
    position: fixed;
    top: 0;
    left: -8%;
    width: 100%;
    z-index: 1000; /* Ensures the navigation is above all other elements */
    overflow: hidden; /* Prevents overflow on small screens */
}

.navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly; /* Evenly space the items, including edges */
    align-items: center; /* Vertically center the text */
    flex-wrap: nowrap; /* Prevents wrapping of items to new lines */
}

.navigation ul li {
    text-align: center; /* Center text in each item */
}

.navigation ul li a {
    color: black;
    font-size: 2.8em;
    font-family: 'Amatic SC', cursive;
    text-decoration: none;
    display: block;
    padding: 5px; /* Padding inside the anchor */
    white-space: nowrap; /* Prevent text from breaking into multiple lines */
}

/* Hover effect */
.navigation ul li a:hover {
    text-decoration: underline;
}

/* Media queries to adjust font-size and spacing for smaller screens */
@media (max-width: 768px) {
    .navigation ul li a {
        font-size: 2em; /* Reduce font size for tablets */
    }
}

@media (max-width: 480px) {
    .navigation {
        padding: 0 5%; /* Reduce padding on mobile */
        left: -5%;
    }

    .navigation ul {
        justify-content: space-between; /* Adjust spacing between items for mobile */
    }

    .navigation ul li a {
        font-size: 1.2em; /* Further reduce font size for mobile */
        padding: 10px; /* Increase padding for better touch targets */
    }
}
